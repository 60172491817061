import { PersonCompanyFunction, PersonFunction } from "../types/Api";
import { AccreditationApplication } from "../types/Api/AccreditationApplication";
import { CurriculumApplicationRotation } from "../types/Api/RegistrarDetails";
import {
  isPracticeManager,
  isPrincipalSupervisor,
  isSupervisor
} from "./Roles/getNonAcrrmRoles";
import {
  companyHasApprovedOrCompletedRotations,
  hasApprovedOrCompletedRotation,
  supervisesCurrentRotation
} from "./rotationsAtCompanyChecks";
import dayjs from "dayjs";
import { flatten } from "lodash";
import { Permission } from "types/Permission";
import { Role } from "types/Role";
import { hasAccessToEmployeeAgreements } from "utils/employeeAgreements/getEmployeeAgreementsAccess";
import type { Module } from "../types/Module";

const ROLES: Role[] = [{ name: "Director of Training (DoT)", functionId: 89 }];
export const ACRRMCompanyId = 1;
export function canAccessModule(
  module: Module,
  personCompanyFunctions: PersonCompanyFunction[],
  personFunctions: PersonFunction[],
  currentCompanyId: number | null,
  companyType: string | null,
  accreditationApplication: AccreditationApplication,
  curriculumApplicationRotation: CurriculumApplicationRotation[]
): boolean {
  if (module === null || module === undefined) {
    throw new Error("module cannot be null or undefined.");
  }

  if (!module.name) {
    throw new Error(
      "module.name cannot be null, undefined or an empty string."
    );
  }

  if (!module.name.trim()) {
    throw new Error("module.name cannot be whitespace.");
  }

  if (!module.roles || module.roles.length === 0) {
    throw new Error(
      "module.roles cannot be null, undefined or an empty array."
    );
  }
  if (personCompanyFunctions === null || personCompanyFunctions === undefined) {
    throw new Error("personCompanyFunctions cannot be null or undefined.");
  }

  if (personFunctions === null || personFunctions === undefined) {
    throw new Error("personFunctions cannot be null or undefined.");
  }

  if (
    currentCompanyId === undefined ||
    (currentCompanyId != null && currentCompanyId <= 0)
  ) {
    throw new Error(
      "currentCompanyId must be either null or a positive number."
    );
  }

  const currentDate = dayjs();

  // If acting under a company, check personCompanyFunctions
  if (currentCompanyId) {
    let currentCompanyFunctions = personCompanyFunctions.filter(
      (f) =>
        f.companyRecordId === currentCompanyId &&
        (!f.functionStartDate ||
          dayjs(f.functionStartDate).year() === 1900 ||
          dayjs(f.functionStartDate).isBefore(currentDate, "d") ||
          dayjs(f.functionStartDate).isSame(currentDate, "d")) &&
        (!f.functionEndDate ||
          dayjs(f.functionEndDate).year() === 1900 ||
          dayjs(f.functionEndDate).isSame(currentDate, "d") ||
          dayjs(f.functionEndDate).isAfter(currentDate, "d"))
    );

    if (currentCompanyFunctions.length === 0) {
      return false;
    }
    const isNonAcrrmME = currentCompanyFunctions.some(
      (i) =>
        i.functionName.toLowerCase().trim() === "medical educator" &&
        i.companyRecordId !== ACRRMCompanyId
    );
    const isNonAcrrmRDoT = currentCompanyFunctions.some(
      (i) =>
        i.functionName.toLowerCase().trim() === "director of training (dot)" &&
        i.companyRecordId !== ACRRMCompanyId
    );
    const isNonAcrrmCensor = currentCompanyFunctions.some(
      (i) =>
        i.functionName.toLowerCase().trim() === "censor" &&
        i.companyRecordId !== ACRRMCompanyId
    );
    const isNonAcrrmCEandMentor = currentCompanyFunctions.some(
      (i) =>
        i.functionName.toLowerCase().trim() ===
          "cultural educator and mentor" && i.companyRecordId !== ACRRMCompanyId
    );

    // If ME Linked to any Company other than ACRRM can only access the dashboard + Tasks page but if there is other CompanyFunction Linked as well, ignore this.
    if (
      (isNonAcrrmME ||
        isNonAcrrmRDoT ||
        isNonAcrrmCensor ||
        isNonAcrrmCEandMentor) &&
      !currentCompanyFunctions.some(
        (i) =>
          i.functionName.toLowerCase().trim() === "supervisor" ||
          i.functionName.toLowerCase().trim() === "principal supervisor" ||
          i.functionName.toLowerCase().trim() === "practice manager"
      )
    ) {
      return module.name === "Dashboard" || module.name === "My Tasks";
    }
    // If currentCompanyFunctions has more that one function remove non acrrm functions as Active function takes precedence.
    if (
      (isNonAcrrmME ||
        isNonAcrrmRDoT ||
        isNonAcrrmCensor ||
        isNonAcrrmCEandMentor) &&
      currentCompanyFunctions.some(
        (i) =>
          i.functionName.toLowerCase().trim() === "supervisor" ||
          i.functionName.toLowerCase().trim() === "principal supervisor" ||
          i.functionName.toLowerCase().trim() === "practice manager"
      )
    ) {
      currentCompanyFunctions = currentCompanyFunctions.filter(
        (i) =>
          i.functionName.toLowerCase().trim() !== "medical educator" &&
          i.companyRecordId !== ACRRMCompanyId
      );
      currentCompanyFunctions = currentCompanyFunctions.filter(
        (i) =>
          i.functionName.toLowerCase().trim() !==
            "director of training (dot)" && i.companyRecordId !== ACRRMCompanyId
      );
      currentCompanyFunctions = currentCompanyFunctions.filter(
        (i) =>
          i.functionName.toLowerCase().trim() !== "censor" &&
          i.companyRecordId !== ACRRMCompanyId
      );

      currentCompanyFunctions = currentCompanyFunctions.filter(
        (i) =>
          i.functionName.toLowerCase().trim() !==
            "cultural educator and mentor" &&
          i.companyRecordId !== ACRRMCompanyId
      );
    }

    // Standard function check
    // Does this user actually have any functions that match on an allowed role for this module?
    const hasFunctionMatchingAllowedRoles = currentCompanyFunctions.some((i) =>
      module.roles.some(
        (j) =>
          j.role.toLowerCase().trim() === i.functionName.toLowerCase().trim()
      )
    );
    if (!hasFunctionMatchingAllowedRoles) return false;

    let accessToEmployeeAgreements = false;
    const isModuleEmployeeAgreements = module.name === "Employee Agreements";

    if (isModuleEmployeeAgreements) {
      accessToEmployeeAgreements = hasAccessToEmployeeAgreements(
        personCompanyFunctions,
        currentCompanyId,
        companyType
      );
    }

    const meetsCompanyTypeRequirements =
      !isModuleEmployeeAgreements || accessToEmployeeAgreements;
    if (!meetsCompanyTypeRequirements) return false;

    const isPracticeManagerSupervisorOrPrincipalSupervisorOnly =
      currentCompanyFunctions.every(
        (i) =>
          i.functionName.toLowerCase().trim() === "supervisor" ||
          i.functionName.toLowerCase().trim() === "principal supervisor" ||
          i.functionName.toLowerCase().trim() === "practice manager"
      );

    if (isPracticeManagerSupervisorOrPrincipalSupervisorOnly) {
      const companyHasApprovedAccreditationApplication =
        accreditationApplication &&
        accreditationApplication.status &&
        (accreditationApplication.status.toLowerCase().trim() === "approved" ||
          accreditationApplication.status.toLowerCase().trim() ===
            "approved provisional");

      // If no approved accreditation, practice managers and supervisors can only access the standard menus
      const isAlwaysAllowedMenu =
        module.name === "Dashboard" ||
        module.name === "Accreditation" ||
        module.name === "My Tasks";
      if (!companyHasApprovedAccreditationApplication) {
        return isAlwaysAllowedMenu;
      }

      //
      // Rules specifically for:
      // - Practice Manager, Supervisor and Principal Supervisor
      // access to:
      // - Registrars and CGT Reports menus...
      //
      const userIsPracticeManager = isPracticeManager(
        personCompanyFunctions,
        currentCompanyId
      );

      const userIsPrincipalSupervisor = isPrincipalSupervisor(
        personCompanyFunctions,
        currentCompanyId
      );

      const userIsSupervisor = isSupervisor(
        personCompanyFunctions,
        currentCompanyId
      );

      const userPersonId = personCompanyFunctions[0].personId;

      let userSupervisesCurrentRotation = false;
      if (userIsSupervisor || userIsPrincipalSupervisor) {
        // Is there a current placement supervised by the user?
        userSupervisesCurrentRotation = supervisesCurrentRotation(
          curriculumApplicationRotation,
          currentCompanyId,
          userPersonId
        );
      }

      let userHasApprovedOrCompletedRotation = false;
      if (userIsSupervisor && !userSupervisesCurrentRotation) {
        // Is there is an approved or completed placement supervised by user?
        userHasApprovedOrCompletedRotation = hasApprovedOrCompletedRotation(
          curriculumApplicationRotation,
          currentCompanyId,
          userPersonId
        );
      }

      let currentCompanyHasApprovedOrCompletedRotations = false;
      if (userIsPrincipalSupervisor) {
        currentCompanyHasApprovedOrCompletedRotations =
          companyHasApprovedOrCompletedRotations(
            curriculumApplicationRotation,
            currentCompanyId
          );
      }
      // Principal Supervisors can always see the CGT Reports menu item but Supervisors must be (or have been) supervising a current, approved, or completed placement
      const isReportsOrCgtReportsModule =
        module.name === "Reports" ||
        module.name === "Core Generalist Training Reports";

      const meetsConditionsForCgtReportsModule =
        (userIsPrincipalSupervisor &&
          currentCompanyHasApprovedOrCompletedRotations) ||
        (userIsSupervisor &&
          (userSupervisesCurrentRotation ||
            userHasApprovedOrCompletedRotation));

      // The remaining modules for which access is otherwise granted are only restricted (for Supervisors and Principal Supervisors). They must be supervising a current placement
      const isRegistrarsModule = module.name === "Registrars";

      const meetsConditionsForRegistrarsModule =
        (userIsSupervisor || userIsPrincipalSupervisor) &&
        userSupervisesCurrentRotation;

      const isOtherSupervisorModule =
        module.name === "Training Post" ||
        module.name === "Organisation" ||
        module.name === "Training Post Profile" ||
        module.name === "Capacity Collection";

      const meetsConditionsForOtherSupervisorModules =
        (userIsSupervisor || userIsPrincipalSupervisor) &&
        userSupervisesCurrentRotation;

      // Grant access to:
      // - Modules with roles matching Person Company Functions where:
      //   - The module is always allowed (Dashboard, Accreditation, My Tasks)
      //   - OR The module is NOT Registrars or CGT Reports, and
      //     - the User is a Practice Manager
      //   - OR The module is one that has requirements specific to Supervisors & Principal Supervisors, and
      //     - those conditions have been met
      //   - OR The module is CGT Reports and conditions for CGT reports have been met
      return (
        hasFunctionMatchingAllowedRoles &&
        meetsCompanyTypeRequirements &&
        (isAlwaysAllowedMenu ||
          (userIsPracticeManager &&
            !isReportsOrCgtReportsModule &&
            !isRegistrarsModule) ||
          (isRegistrarsModule && meetsConditionsForRegistrarsModule) ||
          (isOtherSupervisorModule &&
            meetsConditionsForOtherSupervisorModules) ||
          (isReportsOrCgtReportsModule && meetsConditionsForCgtReportsModule))
      );
    }

    // If none of the conditions above have caused this function to throw an error or return a result...
    // ... execution will fall through to here.
    return hasFunctionMatchingAllowedRoles;
  }

  // If not acting under a company, check person functions instead
  return personFunctions.some((f) =>
    module.roles.some(
      (j) =>
        j.companyNotRequired &&
        j.role.toLowerCase().trim() === f.functionName.toLowerCase().trim() &&
        (!f.functionStartDate ||
          dayjs(f.functionStartDate).year() === 1900 ||
          dayjs(f.functionStartDate) <= currentDate) &&
        (!f.functionEndDate ||
          dayjs(f.functionEndDate).year() === 1900 ||
          dayjs(f.functionEndDate) >= currentDate)
    )
  );
}

export function getModulePermissions(
  module: Module,
  personCompanyFunctions: PersonCompanyFunction[],
  personFunctions: PersonFunction[],
  currentCompanyId: number
): Permission[] {
  let permissions = [] as Permission[];
  // build up permissions list from module + functions
  if (currentCompanyId) {
    // check personCompanyFunctions
    const roles = module.roles.filter((i) =>
      personCompanyFunctions.some(
        (j) =>
          j.companyRecordId === currentCompanyId &&
          j.functionName.toLowerCase() === i.role.toLowerCase()
      )
    );
    permissions = flatten(roles.map((i) => i.permissions));
  } else {
    // check personFunctions
    const roles = module.roles.filter((i) =>
      personFunctions.some(
        (j) => j.functionName.toLowerCase() === i.role.toLowerCase()
      )
    );
    permissions = flatten(roles.map((i) => i.permissions));
  }
  return permissions;
}

export function getRoleFunctionIdByName(roleName: string): number | undefined {
  const role = ROLES.find((role: Role) => role.name === roleName);
  return role ? role.functionId : undefined;
}

export function getRoleNameByFunctionId(
  functionId: number
): string | undefined {
  const role = ROLES.find((role: Role) => role.functionId === functionId);
  return role ? role.name : undefined;
}
