import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { getConfig } from "../getConfig";
import { useAxios } from "../lib/axios";
import * as modules from "../modules";
import { CurriculumApplicationRotation } from "../types/Api/RegistrarDetails";
import { becomeAccredited } from "../utils/accreditation/BecomeAccredited";
import {
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage
} from "../utils/localStorage";
import { useBecomingAccreditedContext } from "./becomeAccreditedContext";
import { useBecomeAccreditedDataContext } from "./becomeAccreditedDataContext";
import { useDisconnectContext } from "./disconnectContext";
import { useConfiguration, useFeatureFlag } from "./useFeatureFlag";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { NameValue } from "types/NameValue";
import { stringAscendingComparator } from "utils/sort";
import type {
  AccreditationApplication,
  ApiResponse,
  PersonCompanyFunction,
  PersonFunction
} from "../types/Api";
import type { AxiosResponse } from "axios";

const CompanyContext = createContext({
  currentCompanyId: null as number,
  personCompanyFunctions: null as PersonCompanyFunction[],
  personFunctions: null as PersonFunction[],
  distinctCompanies: null as NameValue<number>[],
  loading: false,
  loadingBecomeAccredited: false,
  haveAttemptedPCFLoading: false,
  haveAttemptedPFLoading: false,
  accreditationApplication: null as { id: number; status: string },
  curriculumApplicationRotation: null as CurriculumApplicationRotation[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentCompanyId: (_companyId: number) => {},
  personId: -1,
  companyType: null as string,
  refreshContext: async () => {}
});
const useCompanyContext = () => useContext(CompanyContext);
type CompanyContextProviderProps = {
  children: ReactNode;
};
const CompanyContextProvider: FC<CompanyContextProviderProps> = (
  props: CompanyContextProviderProps
) => {
  const { children } = props;
  const { user, isLoading: loadingUser } = useAuth0();
  const [haveAttemptedPFLoading, setHaveAttemptedPFLoading] = useState(false);
  const [haveAttemptedPCFLoading, setHaveAttemptedPCFLoading] = useState(false);
  const getLocalCurrentCompanyId = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_companyId", user.sub) === null
        ? (null as number)
        : Number.parseInt(getItemLocalStorage("acrrm_companyId", user.sub))
      : (null as number);
  };
  const getPersistCompanyId = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_persistcompanyId", user.sub) === null
        ? (null as number)
        : Number.parseInt(
            getItemLocalStorage("acrrm_persistcompanyId", user.sub)
          )
      : (null as number);
  };
  const getLocalPersonCompanyFunctions = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_personCompanyFunctions", user.sub) === null
        ? ([] as PersonCompanyFunction[])
        : (JSON.parse(
            getItemLocalStorage("acrrm_personCompanyFunctions", user.sub)
          ) as PersonCompanyFunction[])
      : (null as PersonCompanyFunction[]);
  };
  const getLocalPersonFunctions = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_personFunctions", user.sub) === null
        ? ([] as PersonFunction[])
        : (JSON.parse(
            getItemLocalStorage("acrrm_personFunctions", user.sub)
          ) as PersonFunction[])
      : (null as PersonFunction[]);
  };
  const getLocalAccreditationApplication = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_accreditationApplication", user.sub) === null
        ? (null as { id: number; status: string })
        : (JSON.parse(
            getItemLocalStorage("acrrm_accreditationApplication", user.sub)
          ) as { id: number; status: string })
      : (null as { id: number; status: string });
  };
  const [currentCompanyId, setCurrentCompanyId] = useState(
    getLocalCurrentCompanyId()
  );
  const [personCompanyFunctions, setPersonCompanyFunctions] = useState(
    getLocalPersonCompanyFunctions()
  );
  const [personFunctions, setPersonFunctions] = useState(
    getLocalPersonFunctions()
  );
  const getLocalCurriculumApplicationRotation = () => {
    return user?.sub && !loadingUser
      ? getItemLocalStorage("acrrm_curriculumApplicationRotation", user.sub) ===
        null
        ? (null as CurriculumApplicationRotation[])
        : (JSON.parse(
            getItemLocalStorage("acrrm_curriculumApplicationRotation", user.sub)
          ) as CurriculumApplicationRotation[])
      : (null as CurriculumApplicationRotation[]);
  };
  const [accreditationApplication, setAccreditationApplication] = useState(
    getLocalAccreditationApplication()
  );
  const [curriculumApplicationRotation, setCurriculumApplicationRotation] =
    useState(getLocalCurriculumApplicationRotation());
  const { submittingBecomeAccredited } = useBecomingAccreditedContext();
  const { axiosInstance } = useAxios();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingPersonFunctions, setLoadingPersonFunctions] = useState(false);
  const [loadingAccreditationApplication, setLoadingAccreditationApplication] =
    useState(false);
  const [loadingRotationDetails, setLoadingRotationDetails] = useState(false);
  const config = getConfig();
  const { enabled: removeAccessEnabled } = useFeatureFlag(
    config.featureFlagRemoveAccessName
  );
  const { config: removeAccessRoles } = useConfiguration<string[]>(
    config.configRemoveAccessName
  );
  const { manageDisconnectState } = useDisconnectContext();
  const {
    personalDetails,
    accreditationDetails,
    healthServiceDetails,
    accountDetails,
    loadingBecomeAccredited,
    setLoadingBecomeAccredited,
    clearBecomeAccreditedData
  } = useBecomeAccreditedDataContext();

  useEffect(() => {
    const data = {
      personalDetails,
      accreditationDetails,
      healthServiceDetails,
      accountDetails
    };
    if (!loadingUser && user && loadingBecomeAccredited && data) {
      const onSubmitFinish = (_wasSuccessful: boolean): void => {
        refreshContext();
        setLoadingBecomeAccredited(false);
        clearBecomeAccreditedData();
      };
      becomeAccredited(data, axiosInstance, onSubmitFinish);
    }
  }, [loadingBecomeAccredited, user, loadingUser]);

  const getPersonCompanyFunctions = async () => {
    manageDisconnectState(false, "");
    setLoadingCompanies(true);
    await axiosInstance
      .get<ApiResponse<PersonCompanyFunction[]>>("/PersonCompanyFunction")
      .then((response: AxiosResponse<ApiResponse<PersonCompanyFunction[]>>) => {
        if (response.status === 200) {
          // Filter out non-B2B and expired functions
          const currentDate = dayjs();
          const b2bFunctions = response.data.result.reduce(
            (a: PersonCompanyFunction[], b: PersonCompanyFunction) => {
              b.functionName = b.functionName.trim();
              if (
                (!b.functionStartDate ||
                  dayjs(b.functionStartDate).year() === 1900 ||
                  dayjs(b.functionStartDate).isBefore(currentDate, "d") ||
                  dayjs(b.functionStartDate).isSame(currentDate, "d")) &&
                (!b.functionEndDate ||
                  dayjs(b.functionEndDate).year() === 1900 ||
                  dayjs(b.functionEndDate).isSame(currentDate, "d") ||
                  dayjs(b.functionEndDate).isAfter(currentDate, "d")) &&
                modules.modules.some((i) =>
                  i.roles.some((j) => j.role === b.functionName)
                ) &&
                (!removeAccessEnabled ||
                  (removeAccessEnabled &&
                    removeAccessRoles.every(
                      (i) => i.toLowerCase() !== b.functionName.toLowerCase()
                    )))
              ) {
                a.push(b);
              }
              return a;
            },
            [] as PersonCompanyFunction[]
          );

          setPersonCompanyFunctions(b2bFunctions);
          if (b2bFunctions.length > 0) {
            const cboCompanyId = getPersistCompanyId();
            if (
              !cboCompanyId ||
              b2bFunctions.every((i) => i.companyRecordId !== cboCompanyId)
            ) {
              const company = b2bFunctions.sort((a, b) =>
                stringAscendingComparator(a.companyName, b.companyName)
              )[0];
              setCurrentCompanyId(company.companyRecordId);
            } else if (
              cboCompanyId &&
              !b2bFunctions.every((i) => i.companyRecordId !== cboCompanyId)
            ) {
              setCurrentCompanyId(cboCompanyId);
            }
          } else {
            setCurrentCompanyId(null);
          }

          manageDisconnectState(false, "");
          setLoadingCompanies(false);
        } else {
          setPersonCompanyFunctions([] as PersonCompanyFunction[]);
          setLoadingCompanies(false);
          if (response.status !== 404) {
            manageDisconnectState(true, "API_ERROR");
            throw new Error(
              response.status +
                " error retrieving Person Company Functions: " +
                response.data?.message
            );
          } else {
            manageDisconnectState(false, "");
          }
        }
      })
      .catch((err: any) => {
        setPersonCompanyFunctions([] as PersonCompanyFunction[]);
        if (!err.response || err.response.status !== 404) {
          manageDisconnectState(true, "API_ERROR");
          throw new Error(err);
        } else {
          manageDisconnectState(false, "");
        }
      })
      .finally(() => {
        setHaveAttemptedPCFLoading(true);
        setLoadingCompanies(false);
      });
  };

  const getPersonFunctions = async () => {
    manageDisconnectState(false, "");
    setLoadingPersonFunctions(true);
    await axiosInstance
      .get<ApiResponse<PersonFunction[]>>("/PersonFunction")
      .then((response: AxiosResponse<ApiResponse<PersonFunction[]>>) => {
        if (response.status === 200) {
          // Filter out non-B2B and expired functions
          const currentDate = dayjs();
          const b2bFunctions = response.data.result.reduce(
            (a: PersonFunction[], b: PersonFunction) => {
              b.functionName = b.functionName.trim();
              if (
                (!b.functionStartDate ||
                  dayjs(b.functionStartDate).year() === 1900 ||
                  dayjs(b.functionStartDate).isBefore(currentDate, "d") ||
                  dayjs(b.functionStartDate).isSame(currentDate, "d")) &&
                (!b.functionEndDate ||
                  dayjs(b.functionEndDate).year() === 1900 ||
                  dayjs(b.functionEndDate).isSame(currentDate, "d") ||
                  dayjs(b.functionEndDate).isAfter(currentDate, "d")) &&
                modules.modules.some((i) =>
                  i.roles.some((j) => j.role === b.functionName)
                ) &&
                (!removeAccessEnabled ||
                  (removeAccessEnabled &&
                    removeAccessRoles.every(
                      (i) => i.toLowerCase() !== b.functionName.toLowerCase()
                    )))
              ) {
                a.push(b);
              }
              return a;
            },
            [] as PersonFunction[]
          );
          setPersonFunctions(b2bFunctions);
          manageDisconnectState(false, "");
          setLoadingPersonFunctions(false);
        } else {
          setPersonFunctions([] as PersonFunction[]);
          setLoadingPersonFunctions(false);
          if (response.status === 404) {
            // 404 errors are not disconnects
            manageDisconnectState(false, "");
          } else {
            manageDisconnectState(true, "API_ERROR");
            throw new Error(
              response.status +
                " error retrieving Person Functions: " +
                response.data?.message
            );
          }
        }
      })
      .catch((err: any) => {
        setPersonFunctions([] as PersonFunction[]);
        if (err.response && err.response.status === 404) {
          manageDisconnectState(false, "");
        }

        if (!err.response || err.response.status !== 404) {
          manageDisconnectState(true, "API_ERROR");
          throw err;
        }
      })
      .finally(() => {
        setHaveAttemptedPFLoading(true);
        setLoadingPersonFunctions(false);
      });
  };

  useEffect(() => {
    if (!loadingUser && user && !submittingBecomeAccredited) {
      if (!personCompanyFunctions) {
        getPersonCompanyFunctions();
      }
      if (!personFunctions) {
        getPersonFunctions();
      }
    }
  }, [
    user,
    loadingUser,
    submittingBecomeAccredited,
    removeAccessEnabled,
    removeAccessRoles
  ]);

  useEffect(() => {
    let supervisor: boolean;
    let practiceManager: boolean;
    if (personCompanyFunctions && personCompanyFunctions.length > 0) {
      supervisor = personCompanyFunctions.some(
        (i) =>
          i.companyRecordId === currentCompanyId &&
          (i.functionName.toLowerCase().trim() === "supervisor" ||
            i.functionName.toLowerCase().trim() === "principal supervisor")
      );
      practiceManager = personCompanyFunctions.some(
        (i) =>
          i.companyRecordId === currentCompanyId &&
          i.functionName.toLowerCase().trim() === "practice manager"
      );
    }
    if (supervisor || practiceManager) {
      const getRotationDetails = async () => {
        setLoadingRotationDetails(true);
        manageDisconnectState(false, "");

        await axiosInstance
          .get<ApiResponse<CurriculumApplicationRotation[]>>(
            `CurriculumApplicationRotation/Company/${currentCompanyId}`
          )
          .then(
            (
              response: AxiosResponse<
                ApiResponse<CurriculumApplicationRotation[]>
              >
            ) => {
              if (response.status === 200) {
                setCurriculumApplicationRotation(response.data.result);
                setLoadingRotationDetails(false);
                manageDisconnectState(false, "");
              } else if (response.status === 404) {
                setCurriculumApplicationRotation(
                  [] as CurriculumApplicationRotation[]
                );
                setLoadingRotationDetails(false);
                manageDisconnectState(false, "");
              } else {
                manageDisconnectState(true, "API_ERROR");

                throw new Error(
                  response.status +
                    " error retrieving Company Rotation Details. " +
                    response.data?.message
                );
              }
            }
          )
          .catch((err: any) => {
            if (err.response && err.response.status === 404) {
              setCurriculumApplicationRotation(
                [] as CurriculumApplicationRotation[]
              );

              manageDisconnectState(false, "");

              setLoadingRotationDetails(false);
            } else {
              manageDisconnectState(true, "API_ERROR");

              throw err;
            }
          });
      };
      getRotationDetails();
    }
    if (currentCompanyId && (practiceManager || supervisor)) {
      const getAccreditationApplication = async () => {
        setLoadingAccreditationApplication(true);
        manageDisconnectState(false, "");

        await axiosInstance
          .get<ApiResponse<AccreditationApplication>>(
            `/AccreditationApplication/GetCompanyCurrentAccreditation/${currentCompanyId}`
          )
          .then(
            (
              response: AxiosResponse<ApiResponse<AccreditationApplication>>
            ) => {
              if (response.status === 200) {
                if (response.data && response.data.result) {
                  setAccreditationApplication({
                    id: response.data.result.id,
                    status: response.data.result.status
                  });
                } else {
                  setAccreditationApplication(null);
                }
                manageDisconnectState(false, "");

                setLoadingAccreditationApplication(false);
              } else {
                manageDisconnectState(true, "API_ERROR");

                throw new Error(
                  response.status +
                    " error retrieving current accreditation application: " +
                    response.data?.message
                );
              }
            }
          )
          .catch((err: any) => {
            if (err.response && err.response.status === 403) {
              setAccreditationApplication(null);
              setLoadingAccreditationApplication(false);
              manageDisconnectState(false, "");
            } else {
              manageDisconnectState(true, "API_ERROR");

              throw err;
            }
          });
      };
      getAccreditationApplication();
    }
  }, [personCompanyFunctions, currentCompanyId]);

  useEffect(() => {
    if (currentCompanyId && !loadingUser && user?.sub) {
      if (currentCompanyId != null) {
        setItemLocalStorage(
          "acrrm_companyId",
          user.sub,
          JSON.stringify(currentCompanyId)
        );
        setItemLocalStorage(
          "acrrm_persistcompanyId",
          user.sub,
          JSON.stringify(currentCompanyId)
        );
      }
    } else if (!currentCompanyId && !loadingUser && user?.sub) {
      removeItemLocalStorage("acrrm_companyId", user.sub);
    }
  }, [currentCompanyId, loadingUser, user]);

  useEffect(() => {
    if (accreditationApplication && !loadingUser && user?.sub) {
      setItemLocalStorage(
        "acrrm_accreditationApplication",
        user.sub,
        JSON.stringify(accreditationApplication)
      );
    } else if (!accreditationApplication && !loadingUser && user?.sub) {
      removeItemLocalStorage("acrrm_accreditationApplication", user.sub);
    }
  }, [accreditationApplication, loadingUser, user]);

  useEffect(() => {
    if (curriculumApplicationRotation && !loadingUser && user?.sub) {
      setItemLocalStorage(
        "acrrm_curriculumApplicationRotation",
        user.sub,
        JSON.stringify(curriculumApplicationRotation)
      );
    } else if (!curriculumApplicationRotation && !loadingUser && user?.sub) {
      removeItemLocalStorage("acrrm_curriculumApplicationRotation", user.sub);
    }
  }, [curriculumApplicationRotation, loadingUser, user]);

  useEffect(() => {
    if (personCompanyFunctions && !loadingUser && user?.sub) {
      setItemLocalStorage(
        "acrrm_personCompanyFunctions",
        user.sub,
        JSON.stringify(personCompanyFunctions)
      );
    } else if (!personCompanyFunctions && !loadingUser && user?.sub) {
      removeItemLocalStorage("acrrm_personCompanyFunctions", user.sub);
    }
  }, [personCompanyFunctions, loadingUser, user]);

  useEffect(() => {
    if (personFunctions && !loadingUser && user?.sub) {
      setItemLocalStorage(
        "acrrm_personFunctions",
        user.sub,
        JSON.stringify(personFunctions)
      );
    } else if (!personFunctions && !loadingUser && user?.sub) {
      removeItemLocalStorage("acrrm_personFunctions", user.sub);
    }
  }, [personFunctions, loadingUser, user]);

  const distinctCompanies = useMemo(() => {
    if (!personCompanyFunctions) {
      return [];
    }
    const distinct = personCompanyFunctions.reduce(
      (a: NameValue<number>[], b: PersonCompanyFunction) => {
        if (a.every((i) => i.value !== b.companyRecordId)) {
          a.push({
            name: b.companyName,
            value: b.companyRecordId
          } as NameValue<number>);
        }
        return a;
      },
      [] as NameValue<number>[]
    );
    return distinct;
  }, [personCompanyFunctions]);

  const personId = useMemo(() => {
    let id = -1;
    if (
      personCompanyFunctions &&
      personCompanyFunctions[0] &&
      currentCompanyId
    ) {
      id = personCompanyFunctions.find(
        (funcs) => funcs.companyRecordId === currentCompanyId
      )?.personId;
    }
    return id;
  }, [personCompanyFunctions, currentCompanyId]);

  const companyType = useMemo(() => {
    let type = null;
    if (
      personCompanyFunctions &&
      personCompanyFunctions[0] &&
      currentCompanyId
    ) {
      type = personCompanyFunctions.find(
        (funcs) => funcs.companyRecordId === currentCompanyId
      )?.companyType;
    }
    return type;
  }, [personCompanyFunctions, currentCompanyId]);

  async function refreshContext() {
    await getPersonCompanyFunctions();
    await getPersonFunctions();
  }

  return (
    <CompanyContext.Provider
      value={{
        currentCompanyId,
        personCompanyFunctions,
        personFunctions,
        distinctCompanies,
        loading:
          loadingUser ||
          loadingCompanies ||
          loadingPersonFunctions ||
          loadingAccreditationApplication ||
          loadingRotationDetails,
        haveAttemptedPCFLoading,
        haveAttemptedPFLoading,
        loadingBecomeAccredited,
        accreditationApplication,
        curriculumApplicationRotation,
        setCurrentCompanyId,
        personId,
        companyType,
        refreshContext
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyContextProvider, useCompanyContext };
