import { getConfig } from "../getConfig";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const { uploadApiBaseUrl, apiScopes, auth0AudienceUpload, acrrmAppId } =
  getConfig();

// Setup Axios instance
const axiosInstance = axios.create({
  baseURL: uploadApiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    "ACRRM-APP-ID": acrrmAppId
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const details = error.response?.data || null;

    Sentry.withScope((scope) => {
      scope.setExtra("data", details);
      Sentry.captureException(error);
    });
    throw error;
  }
);

const requestTokenInterceptor: number = null;

// Hook to set up request headers with Auth0 token and return an axios instance
const useAxios = (): {
  axiosInstance: AxiosInstance;
} => {
  const { getAccessTokenSilently } = useAuth0();

  if (requestTokenInterceptor >= 0) {
    axiosInstance.interceptors.request.eject(requestTokenInterceptor);
  }

  // Add request interceptor to inject Authorization header
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    let token = null;
    try {
      token = await getAccessTokenSilently({
        scope: apiScopes,
        audience: auth0AudienceUpload
      });
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      token = null;
      console.error("Error retrieving token:", error);
    }

    return config;
  });
  return { axiosInstance };
};

export { useAxios };
