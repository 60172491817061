import { Module } from "types/Module";

export const modules = [
  {
    name: "Dashboard",
    roles: [
      {
        role: "Practice Manager",
        permissions: ["read"]
      },
      { role: "Supervisor", permissions: ["read"], companyNotRequired: true },
      {
        role: "Principal Supervisor",
        permissions: ["read"],
        companyNotRequired: true
      },
      { role: "Medical Educator", permissions: ["read"] },
      { role: "Censor", permissions: ["read"] },
      { role: "Director of Training (DoT)", permissions: ["read"] },
      { role: "Cultural Educator and Mentor", permissions: ["read"] }
    ],
    path: "/dashboard",
    icon: "dashboard",
    modules: null
  },
  {
    name: "My Tasks",
    roles: [
      {
        role: "Practice Manager",
        permissions: ["read"]
      },
      { role: "Supervisor", permissions: ["read"], companyNotRequired: true },
      {
        role: "Principal Supervisor",
        permissions: ["read"],
        companyNotRequired: true
      },
      { role: "Medical Educator", permissions: ["read"] },
      { role: "Censor", permissions: ["read"] },
      { role: "Director of Training (DoT)", permissions: ["read"] },
      { role: "Cultural Educator and Mentor", permissions: ["read"] }
    ],
    path: "/tasks",
    icon: "add_task",
    modules: null
  },
  {
    name: "Accreditation",
    roles: [
      {
        role: "Practice Manager",
        permissions: ["create", "read", "update", "delete"]
      },
      {
        role: "Supervisor",
        permissions: ["create", "read", "update", "delete"],
        companyNotRequired: true
      },
      {
        role: "Principal Supervisor",
        permissions: ["create", "read", "update", "delete"],
        companyNotRequired: true
      }
    ],
    path: "/accreditation",
    icon: "verified",
    modules: null
  },
  {
    name: "Registrars",
    roles: [
      { role: "Medical Educator", permissions: ["read"] },
      { role: "Censor", permissions: ["read"] },
      { role: "Director of Training (DoT)", permissions: ["read"] },
      { role: "Supervisor", permissions: ["read"] },
      { role: "Principal Supervisor", permissions: ["read"] },
      { role: "Cultural Educator and Mentor", permissions: ["read"] }
    ],
    path: "/registrars",
    icon: "supervisor_account",
    modules: null
  },
  {
    name: "Reports",
    path: "/reports",
    roles: [
      {
        role: "Supervisor",
        permissions: ["create", "read", "update"]
      },
      {
        role: "Principal Supervisor",
        permissions: ["create", "read", "update"]
      },
      {
        role: "Medical Educator",
        permissions: ["create", "read", "update", "delete"]
      }
    ],
    icon: "report_gmail_errorred_icon",
    modules: [
      {
        name: "Core Generalist Training Reports",
        path: "/reports/core-generalist-training-reports",
        roles: [
          {
            role: "Supervisor",
            permissions: ["create", "read", "update"]
          },
          {
            role: "Principal Supervisor",
            permissions: ["create", "read", "update"]
          },
          {
            role: "Medical Educator",
            permissions: ["create", "read", "update"]
          }
        ],
        icon: "",
        modules: null
      },
      {
        name: "Session Reports",
        path: "/reports/session-reports",
        roles: [
          {
            role: "Medical Educator",
            permissions: ["create", "read", "update", "delete"]
          }
        ],
        icon: "timer",
        modules: null
      }
    ]
  },
  {
    name: "Training Post",
    roles: [
      { role: "Practice Manager", permissions: ["read", "update"] },
      { role: "Supervisor", permissions: ["read"] },
      { role: "Principal Supervisor", permissions: ["read"] }
    ],
    path: "/training-post",
    icon: "home_work",
    modules: [
      {
        name: "Organisation",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          },
          { role: "Supervisor", permissions: ["read"] },
          { role: "Principal Supervisor", permissions: ["read"] }
        ],
        path: "/training-post/organisation",
        icon: "",
        modules: null
      },
      {
        name: "Training Post Profile",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          },
          { role: "Supervisor", permissions: ["read"] },
          { role: "Principal Supervisor", permissions: ["read"] }
        ],
        path: "/training-post/training-post-profile",
        icon: "",
        modules: null
      },
      {
        name: "Capacity Collection",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          },
          { role: "Supervisor", permissions: ["read"] },
          { role: "Principal Supervisor", permissions: ["read"] }
        ],
        path: "/training-post/capacity-collection",
        icon: "",
        modules: null
      },
      {
        name: "Employee Agreements",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          }
        ],
        path: "/training-post/employee-agreements",
        icon: "",
        modules: null
      }
    ]
  },
  {
    name: "Payments Receipts",
    roles: [
      {
        role: "Practice Manager",
        permissions: ["create", "read", "update", "delete"]
      }
    ],
    path: "/payments-receipts",
    icon: "attach_money",
    modules: null
  },
  {
    name: "Placement Management",
    roles: [
      {
        role: "Practice Manager",
        permissions: ["read", "update", "delete"]
      }
    ],
    path: "/placement-management",
    icon: "personpincircle",
    modules: [
      {
        name: "Registrar Preferencing",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          }
        ],
        path: "/placement-management/registrar-preferencing",
        icon: "",
        modules: null
      },
      {
        name: "Registrar Offers",
        roles: [
          {
            role: "Practice Manager",
            permissions: ["read", "update", "create"]
          }
        ],
        path: "/placement-management/registrar-offers",
        icon: "",
        modules: null
      }
    ]
  },
  {
    name: "Appointments",
    roles: [
      { role: "Medical Educator", permissions: ["read"] },
      { role: "Censor", permissions: ["read"] },
      { role: "Director of Training (DoT)", permissions: ["read"] }
    ],
    path: "/appointments",
    icon: "event_available",
    modules: null
  },
  {
    name: "Training Posts",
    roles: [
      { role: "Medical Educator", permissions: ["read"] },
      { role: "Director of Training (DoT)", permissions: ["read"] },
      { role: "Cultural Educator and Mentor", permissions: ["read"] }
    ],
    path: "/training-posts",
    icon: "business",
    modules: null
  }
] as Module[];
