import { PersonCompanyFunction } from "../../types/Api";
import { isPracticeManager } from "utils/Roles/getNonAcrrmRoles";

export function hasAccessToEmployeeAgreements(
  personCompanyFunctions: PersonCompanyFunction[],
  currentCompanyId: number,
  companyType: String
): boolean {
  const userIsPracticeManager = isPracticeManager(
    personCompanyFunctions,
    currentCompanyId
  );

  if (
    userIsPracticeManager &&
    companyType.startsWith("Aboriginal Medical Service")
  ) {
    return true;
  }

  return false;
}
