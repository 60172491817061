import { getConfig } from "../getConfig";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import AxiosMockAdapter from "axios-mock-adapter";

const { CancelToken } = axios;
const source = CancelToken.source();
const { apiBaseUrl, apiScopes, auth0Audience, acrrmAppId } = getConfig();

// Setup instance to use in useAxios
const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  cancelToken: source.token,
  headers: {
    "Content-Type": "application/json",
    "ACRRM-APP-ID": acrrmAppId
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const details =
      error.response && error.response.data ? error.response.data : null;

    Sentry.withScope((scope) => {
      scope.setExtra("data", details);
      Sentry.captureException(error);
    });
    throw error;
  }
);

// Setup mock to use for testing
const mock = new AxiosMockAdapter(axiosInstance, {
  delayResponse: 500,
  onNoMatch: "passthrough"
});

let requestTokenInterceptor: number = null;

if (
  window.localStorage.getItem("ACRRM_MOCK_API") === "false" ||
  (window.localStorage.getItem("ACRRM_MOCK_API") === null &&
    process.env.NODE_ENV !== "development")
) {
  if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
    console.log("Removing Mocking Behaviour");
  }
  mock.restore();
}

// Hook to set up request headers with Auth0 token and return an axios instance
const useAxios = (): {
  axiosInstance: AxiosInstance;
} => {
  const { getAccessTokenSilently } = useAuth0();

  if (requestTokenInterceptor >= 0) {
    axiosInstance.interceptors.request.eject(requestTokenInterceptor);
  }

  requestTokenInterceptor = axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
        console.log("getting access token");
      }
      const token = await getAccessTokenSilently({
        scope: apiScopes,
        audience: auth0Audience
      }).then(
        (response) => response,
        () => null
      );
      if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
        console.log("token is", token);
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
  );

  return { axiosInstance };
};

export { mock, useAxios };
