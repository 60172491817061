import { FC, ReactNode, useEffect, useState } from "react";
import LoadingScreen from "../components/molecules/LoadingScreen";
import Unauthorized from "../components/pages/errors/Unauthorized";
import { useCompanyContext } from "../hooks/companyContext";
import * as modulesList from "../modules";
import { canAccessModule } from "../utils/roles";
import { getConfig } from "getConfig";
import { useConfiguration } from "hooks/useFeatureFlag";
import { Module } from "types/Module";
import { findModule } from "utils/modules";

interface RoleGuardProps {
  moduleName: string;
  children: ReactNode;
}

const RoleGuard: FC<RoleGuardProps> = (props: RoleGuardProps) => {
  const { moduleName, children } = props;
  const {
    accreditationApplication,
    currentCompanyId,
    personCompanyFunctions,
    personFunctions,
    loading,
    loadingBecomeAccredited,
    curriculumApplicationRotation,
    companyType
  } = useCompanyContext();

  const [hasPageAccess, setHasPageAccess] = useState(true);

  const modules = modulesList.modules as Module[];
  const module = findModule(moduleName, modules);
  const config = getConfig();

  const { config: removePageAccess, loading: removePageAccessLoading } =
    useConfiguration<string[]>(config.configRemovePageName);

  useEffect(() => {
    if (removePageAccess) {
      const hideModule = removePageAccess.includes(module.name);
      if (hideModule) {
        setHasPageAccess(false);
      } else {
        setHasPageAccess(true);
      }
    } else {
      setHasPageAccess(true);
    }
  }, [removePageAccess, moduleName]);

  if (loading || loadingBecomeAccredited || removePageAccessLoading) {
    return <LoadingScreen />;
  }

  if (
    hasPageAccess &&
    canAccessModule(
      module,
      personCompanyFunctions,
      personFunctions,
      currentCompanyId,
      companyType,
      accreditationApplication,
      curriculumApplicationRotation
    )
  ) {
    return <>{children}</>;
  }
  return <Unauthorized />;
};

export default RoleGuard;
